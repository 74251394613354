import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Terms from './../components/terms'

const IndexPage = () => (
  <Layout isWhiteHeader zindex="3">
    <SEO
      title='Terms'
      keywords={[`web hosting`, `domain names`, `web site`, `search engine optimization`, `hosting`, `servers`]}
      pageType={'company_info'}
      flowType={'none'} />
    <Terms />
  </Layout>
)

export default IndexPage
