import React from 'react'

import './style/terms.scss'

const Terms = () => (
  <section className='bg-lightgray'>
    <div className='content-main'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='index-item'>
              <a href='/terms/terms-of-service'>Terms of Service</a>
              <p>These are the terms you agree to when you use WebsiteBuilder.</p>
            </div>
            <div className='index-item'>
              <a href='https://newfold.com/privacy-center' rel='noopener' target='_blank'>Privacy Notice</a>
              <p>This Privacy Notice applies to the personal data of users collected or used by Endurance International Group, Inc, doing business as WebsiteBuilder.</p>
            </div>
            <div className='index-item'>
              <a href='/terms/acceptable-use'>Acceptable Use</a>
              <p>This Acceptable Use Policy ('AUP') governs your use of the Services and is incorporated by reference into WebsiteBuilder's Terms and Conditions.</p>
            </div>
            <div className='index-item'>
              <a href='/terms/trademark-disputes'>Trademark Disputes</a>
              <p>WebsiteBuilder Trademark Disputes for Domain Names.</p>
            </div>
            <div className='index-item'>
              <a href='/terms/domain-registration-agreement'>Domain Registration</a>
              <p>WebsiteBuilder Domain Registration Agreement.</p>
            </div>
            <div className='index-item'>
              <a href='/terms/copyright-infringement-policy'>Copyright</a>
              <p>Copyright Infringement Policy</p>
            </div>
            <div className='index-item'>
              <a href='/terms/domain-renewal-notification-policy'>DNRN Policy</a>
              <p>WebsiteBuilder Domain Name Renewal Notification Policy.</p>
            </div>
            <div className='index-item'>
              <a href='/terms/data-request-policy'>Data Request Policy</a>
              <p>WebsiteBuilder Data Request Policy.</p>
            </div>
            <div className='index-item'>
              <a href='/terms/arbitration-agreement'>Arbitration Agreement</a>
              <p>WebsiteBuilder Arbitration Agreement.</p>
            </div>
            <div className='index-item'>
              <a href='/terms/professional-services-agreement'>Professional Services Agreement</a>
              <p>WebsiteBuilder Professional Services Agreement.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)
export default Terms
